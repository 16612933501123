div.main-post-container {

    h1 {
        font-weight: 600;
        font-size: 2em;
        margin: 0.67em 0;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }

    h2 {
        font-weight: 600;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }

    h3 {
        font-weight: 600;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    h4 {
        font-weight: 600;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
    }

    h5 {
        font-weight: 600;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
    }

    h6 {
        font-weight: 600;
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
    }

    a {
        text-decoration: underline;
    }

    p {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.read-more {
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%; 
    text-align: center; 
    margin: 0; 
    padding: 30px 0; 
	
    /* "transparent" only works here because == rgba(0,0,0,0) */
    // background-image: linear-gradient(to bottom, transparent, white);
    background-image: linear-gradient(to bottom, transparent, white);
}