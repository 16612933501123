.post-editor {
    min-height: 300px;
    
    h1 {
        font-weight: bold;
        font-size: 2em;
        margin: 0.67em 0;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }

    h2 {
        font-weight: bold;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
    }

    h3 {
        font-weight: bold;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }

    h4 {
        font-weight: bold;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
    }

    h5 {
        font-weight: bold;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
    }

    h6 {
        font-weight: bold;
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
    }

    a {
        text-decoration: underline;
    }
}

.post-toolbar {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px #dedede;

    .rdw-inline-wrapper, .rdw-list-wrapper, .rdw-link-wrapper, .rdw-image-wrapper, .rdw-embedded-wrapper {
        margin: 0;
    }

    .rdw-option-wrapper {
        border: none;
        height: 30px;
        &:hover {
            box-shadow: none;
            background-color: #dedede;
        }
    }
}